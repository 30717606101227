import classnames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '../../../styles/colors';
import Button from '../../Button';
import Logo from '../../Logo';
import Hamburger from './Hamburger';

const HeaderSC = styled.header`
  img,
  ul,
  li,
  a {
    margin: 0;
  }
`;

interface HeaderProps {
  className?: string;
  hasCarousel?: boolean;
  pathname: string;
}
const Header: FC<HeaderProps> = ({
  hasCarousel = false,
  className,
  pathname,
}) => {
  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleChangeLanguage = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const lang = event.currentTarget.getAttribute('data-lang');

    if (lang) {
      await i18n.changeLanguage(lang);
      await navigate(t('pages.accueil.pathname'));
    }
  };

  useEffect(() => {
    if (openMenu) {
      document.documentElement.classList.add('is-clipped');
    } else {
      document.documentElement.classList.remove('is-clipped');
    }
  }, [openMenu]);

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleOnClick = () => {
    setOpenMenu(false);
  };

  const menu = [
    'pages.inventaire',
    'pages.objets-vendus',
    'pages.a-propos',
    'pages.contact',
    'pages.panier',
  ];

  return (
    <HeaderSC
      className={classnames(
        'flex flex-col items-center md:mx-12 w-full md:w-auto mb-10',
        {
          'bg-black-300': hasCarousel,
          'bg-opacity-0': hasCarousel,
          'h-header-mobile-carousel md:h-screen': hasCarousel,
        },
        className,
      )}
    >
      <div
        className={classnames(
          'relative flex flex-col lg:flex-row items-center justify-center w-full',
          'pb-2 md:py-5 w-full box-border',
          {
            // 'bg-opacity-80': hasCarousel,
            'bg-transparent': hasCarousel,
          },
        )}
      >
        <Hamburger handleToggleMenu={handleToggleMenu} openMenu={openMenu} />
        <Link
          aria-label="Barrois Antiques"
          className="lg:absolute lg:left-0"
          to={t('pages.accueil.pathname')}
        >
          <Logo
            color={colors.neutral[700]}
            colorSecondary={colors.neutral[500]}
            height={48}
            width={230}
          />
        </Link>

        <ul
          className={classnames('font-serif text-lg lg:!flex text-center', {
            hidden: !openMenu,
          })}
        >
          {menu.map(item => (
            <li key={item} className="">
              <Link
                className={classnames(
                  'whitespace-nowrap py-0 px-3 opacity-100',
                  'text-dark-500 hover:text-secondary-500',
                  {
                    '!text-secondary-700': pathname === t(`${item}.pathname`),
                    'md:text-dark-50': hasCarousel,
                  },
                )}
                onClick={handleOnClick}
                to={t(`${item}.pathname`)}
              >
                {t(`${item}.menu.label`)}
              </Link>
            </li>
          ))}
        </ul>

        <div className="hidden lg:absolute md:right-0 lg:flex items-center justify-center text-xs space-x-2">
          <Button data-lang="fr" onClick={handleChangeLanguage} status="link">
            FR
          </Button>
          <span>/</span>
          <Button data-lang="en" onClick={handleChangeLanguage} status="link">
            EN
          </Button>
        </div>
        {/* <Panier */}
        {/*  className="absolute md:relative top-0 right-0" */}
        {/*  hasCarousel={hasCarousel} */}
        {/* /> */}
      </div>
    </HeaderSC>
  );
};

export default Header;
