import { BreadcrumbType, PageNode } from '@innedit/innedit-type';
import classnames from 'classnames';
import { Link } from 'gatsby';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import Content from '../Content';
import SubMenu from '../SubMenu';
import Title from '../Title';
import BannerTop from './BannerTop';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import Header from './Header/index';

export interface LayoutProps {
  breadcrumbs?: BreadcrumbType[];
  breadcrumbsTitle?: string;
  canonical?: string;
  carousel?: any[];
  head?: {
    language?: string;
    meta?: any;
    title?: string;
  };
  loading?: boolean;
  pathname: string;
  showTitle?: boolean;
  subPages?: PageNode[];
  title?: string;
  titleClassName?: string;
}

const usePreviousLang = (value?: string): string | undefined => {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const Layout: FC<PropsWithChildren<LayoutProps>> = function ({
  breadcrumbs,
  breadcrumbsTitle,
  canonical,
  carousel,
  children,
  head,
  loading,
  pathname,
  showTitle,
  subPages,
  title,
  titleClassName = 'my-20 mx-6 md:mx-auto text-3xl md:text-5xl text-center',
}) {
  const [navigatorLanguage, setNavigatorLanguage] = useState<string>();
  const [lang] = head?.language
    ? [head.language]
    : pathname.substr(1).split('/');
  const previousLang = usePreviousLang(lang || 'fr');
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (window.navigator.language) {
      const [newNavigatorLanguage] = window.navigator.language.split('-');
      setNavigatorLanguage(newNavigatorLanguage);
    }
  }, []);

  useEffect(() => {
    if (
      ['en', 'fr'].includes(lang) &&
      language !== lang &&
      previousLang !== lang
    ) {
      i18n.changeLanguage(lang);
    }
  }, [language, lang, previousLang]);

  const pageTitle =
    head && head.title ? t(head.title) : (title && t(title)) || '';

  return (
    <div className="flex flex-col md:min-h-screen">
      <Helmet
        htmlAttributes={{ lang: lang || language || 'fr' }}
        meta={head && head.meta ? head.meta : []}
        title={`${pageTitle ? `${pageTitle} - ` : ''}${
          process.env.GATSBY_SITE_NAME
        }`}
      >
        <link
          href={
            canonical ?? `${process.env.GATSBY_WEBSITE_DOMAIN_NAME}${pathname}`
          }
          rel="canonical"
        />
      </Helmet>

      {'/' === pathname &&
        undefined !== navigatorLanguage &&
        undefined !== language &&
        'fr' !== navigatorLanguage &&
        navigatorLanguage !== language && (
          <div className="bg-light-50 flex items-center justify-around pb-2">
            <span className="italic text-sm">
              You are located outside of France. We will help you with the
              possibility of viewing the site in English.
            </span>
            <Link to="/en/">Change language</Link>
          </div>
        )}
      <BannerTop />
      <Header
        className={classnames('z-0', {
          'h-v-2/3': carousel,
        })}
        pathname={pathname}
      />
      <div
        className={classnames('flex flex-col flex-1', {
          'md:mt-10': !showTitle || !title,
        })}
      >
        {showTitle && title && (
          <Title className={titleClassName}>{t(title)}</Title>
        )}
        {breadcrumbs && breadcrumbsTitle && (
          <Breadcrumb items={breadcrumbs} title={breadcrumbsTitle} />
        )}
        {subPages && <SubMenu datas={subPages} />}
        {loading ? <Content>{t('loading')}</Content> : children}
      </div>
      <Footer pathname={pathname} />
    </div>
  );
};

export default Layout;
