import { BannerType } from '@innedit/innedit-type';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

const BannerTop: FC = function () {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      allBanner(filter: { hidden: { eq: false }, position: { eq: "top" } }) {
        edges {
          node {
            name
            body
          }
        }
      }
    }
  `);

  if (!data.allBanner.edges || 0 === data.allBanner.edges.length) {
    return null;
  }

  const banner = data.allBanner.edges[0].node as BannerType;

  return (
    <div className="md:-mt-3 flex items-center justify-center text-sm h-8 text-asphalt-400 bg-white-50 mb-5 md:mb-0">
      {banner.body ?? banner.name}
    </div>
  );
};

export default BannerTop;
