// import { useSelector } from '@innedit/formidable';
// import { UserData } from '@innedit/innedit';
import { Link, navigate } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

// import { StateProps } from '../../../reducers';
import Button from '../../Button';

const Footer: FC<{ pathname: string }> = function ({ pathname }) {
  const { t, i18n } = useTranslation();

  // const { authUser } = useSelector((state: StateProps) => state.user);

  const handleChangeLanguage = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const lang = event.currentTarget.getAttribute('data-lang');

    if (lang) {
      i18n
        .changeLanguage(lang)
        .then(() => navigate(t('pages.accueil.pathname')))
        .catch(console.error);
    }
  };

  // const handleLogoutOnClick = async () => {
  //   await UserData.signOut();
  //
  //   return navigate('/');
  // };

  return (
    <footer className="mt-20 pt-3 bg-white-50 px-6 md:px-0">
      <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-around">
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.address.title')}
          </h3>
          <ul>
            <li>53, rue du Vieux Four</li>
            <li>59700 Marcq-en-Baroeul</li>
            <li>France</li>
          </ul>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.categories.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li>
                <Link to={t('footer.categories.values.meubles.pathname')}>
                  {t('footer.categories.values.meubles.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.tables.pathname')}>
                  {t('footer.categories.values.tables.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.luminaires.pathname')}>
                  {t('footer.categories.values.luminaires.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.cheminees.pathname')}>
                  {t('footer.categories.values.cheminees.label')}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to={t('footer.categories.values.divers.pathname')}>
                  {t('footer.categories.values.divers.label')}
                </Link>
              </li>
              <li>
                <Link
                  to={t('footer.categories.values.decoration-murales.pathname')}
                >
                  {t('footer.categories.values.decoration-murales.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.sieges.pathname')}>
                  {t('footer.categories.values.sieges.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.art-table.pathname')}>
                  {t('footer.categories.values.art-table.label')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.liens.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li>
                <Link to={t('footer.liens.values.newsletter.pathname')}>
                  {t('footer.liens.values.newsletter.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.liens.values.contact.pathname')}>
                  {t('footer.liens.values.contact.label')}
                </Link>
              </li>

              {/* {authUser?.isAnonymous ? ( */}
              {/*  <li> */}
              {/*    <Link to={t('footer.liens.values.login.pathname')}> */}
              {/*      {t('footer.liens.values.login.label')} */}
              {/*    </Link> */}
              {/*  </li> */}
              {/* ) : ( */}
              {/*  <> */}
              {/*    <li> */}
              {/*      <Link to={t('footer.liens.values.commandes.pathname')}> */}
              {/*        {t('footer.liens.values.commandes.label')} */}
              {/*      </Link> */}
              {/*    </li> */}
              {/*    <li> */}
              {/*      <Button onClick={handleLogoutOnClick} status="link"> */}
              {/*        {t('footer.liens.values.logout.label')} */}
              {/*      </Button> */}
              {/*    </li> */}
              {/*  </> */}
              {/* )} */}
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.contact.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li className="label">{t('footer.contact.phone')}</li>
              <li>World: 011 336 1237 5555</li>
              <li>Europe: +33 6 12 37 55 55</li>
              <li>France: 06 12 37 55 55</li>
            </ul>
            <ul>
              <li className="label">{t('footer.contact.email')}</li>
              <li>
                <a href="mailto:info@barroisantiques.com">
                  info@barroisantiques.com
                </a>
              </li>
              <li>{t('footer.contact.ouverture-sur-rdv')}</li>
            </ul>
          </div>
        </div>
      </div>
      <ul className="flex items-center justify-center text-xs mt-10 space-x-10">
        <li>
          <Button data-lang="fr" onClick={handleChangeLanguage} status="link">
            Français
          </Button>
        </li>
        <li>
          <Button data-lang="en" onClick={handleChangeLanguage} status="link">
            English
          </Button>
        </li>
      </ul>
      <ul className="flex items-center justify-center text-xs mt-10">
        <li>
          Copyright © 2021 Barroisantiques.com - Barrois Antiques.{' '}
          {t('footer.copyright.pense-et-concu-par')}{' '}
          <a href="//www.innedit.fr">Innédit</a>.{' '}
          {t('footer.copyright.tous-droits-reserves')}.
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
